import React from 'react';
import {ButtonRGB} from "./styles";

const ButtonGuzbarraf = ({onClick, text, bgHover}) => {

  return (
    <ButtonRGB
      onClick={onClick}
      bghover={bgHover}
    >
      {text}
    </ButtonRGB>
  )
}

export default ButtonGuzbarraf
