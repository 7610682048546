import styled from 'styled-components';
import {Button} from 'bloomer'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const ButtonRGB = styled(Button)`
  
  font: 20px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  
  border: none;
  border-radius: 0;
  background-color: ${GlobalBackgroundColors.bgTertiary};
  
  &:hover{
    color: ${GlobalColors.colorLinkHover};
    background-color: ${props => props.bghover || GlobalBackgroundColors.bgSecondary};
  }
`
