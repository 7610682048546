import styled from 'styled-components';
import {Input} from "bloomer";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const InputTextRGB = styled(Input)`
  
  font: 20px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${GlobalColors.colorPrimary};
  background-color: transparent;
  
  &:focus{
    box-shadow: none;
  }
  &:hover{
    border-bottom: 1px solid ${GlobalColors.colorPrimary};
  }
`
