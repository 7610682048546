import React, {useState} from "react"
import axios from "axios"
import {
  Field,
  FieldLabel,
  FieldBody,
  Control,
  Modal,
  ModalBackground,
  ModalContent,
  ModalClose,
  Column, Columns
} from "bloomer";
import {
  LabelBloomer,
  ContFormContacto, ContMessage, GlobalStylesFormContact
} from "./stylesFormContact";
import ButtonGuzbarraf from "../UI/Button";
import InputTextGuzbarraf from "../UI/InputText";
import TextAreaGuzbarraf from "../UI/TextArea";

const FormContactComp = () => {
  const bolHorizontal = false;
  const [showMessage, setShowMessage] = useState(false);

  const handleSendFormContact = () => {
    const url = 'https://theoceangroup.mx/send-email/?mod=email';
    // const url = 'http://localhost:8888/TheOceanGroup/The-Ocean-Group-Email//?mod=email';

    let txtNombre = document.querySelector('input[name="txtNombre"]').value;
    let txtEmail = document.querySelector('input[name="txtEmail"]').value;
    let txtTelefono = document.querySelector('input[name="txtTelefono"]').value;
    let txtMensaje = document.querySelector('textarea[name="txtMensaje"]').value;

    //console.log('formData => ', formData);
    //console.log('txtNombre => ', document.querySelector('input[name="txtNombre"]').value);

    if ( txtNombre === null || txtNombre.length === 0 || /^\s+$/.test(txtNombre) ) {
      let message = 'Escriba su nombre';
      document.getElementById('msg-content').innerHTML = message;
      setShowMessage(true);
      return false;
    }
    if ( txtEmail === null || txtEmail.length === 0 || /^\s+$/.test(txtEmail) ) {
      let message = 'Escriba su email';
      document.getElementById('msg-content').innerHTML = message;
      setShowMessage(true);
      return false;
    } else {
      if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(txtEmail) ) {
        if ( txtTelefono === null || txtTelefono.length === 0 || /^\s+$/.test(txtTelefono) ) {
          let message = 'Escriba su teléfono';
          document.getElementById('msg-content').innerHTML = message;
          setShowMessage(true);
          return false;
        }

        if ( txtMensaje === null || txtMensaje.length === 0 || /^\s+$/.test(txtMensaje) ) {
          let message = 'Escriba su mensaje';
          document.getElementById('msg-content').innerHTML = message;
          setShowMessage(true);
          return false;
        }

        const params = new URLSearchParams();
        params.append('txtNombre', txtNombre);
        params.append('txtEmail', txtEmail);
        params.append('txtTelefono', txtTelefono);
        params.append('txtMensaje', txtMensaje);

        axios({
          method: 'post',
          url: url,
          data: params
        }).then(function (response) {
          //console.log(response);
          //console.log(response.data);

          //let success = response.data.success;
          let message = response.data.data.message;

          document.getElementById('msg-content').innerHTML = message;
          setShowMessage(true);

        })
        .catch(function (error) {
          //console.log(error);
          document.getElementById('msg-content').innerHTML = error;
          setShowMessage(true);
        });

      } else {
        let message = 'el formato de email no es correcto';
        document.getElementById('msg-content').innerHTML = message;
        setShowMessage(true);
        return false;
      }
    }
  }

  const handleCloseMessage = () => {
    //console.log('handleCloseMessage');
    setShowMessage(false);
  }

  return(
    <ContFormContacto>

      <GlobalStylesFormContact/>

      <form method='POST' id={'frmContact'} name={'frmContact'} action='#'>
        <Columns className="columns-responsive full-height">

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal={false}>
                <LabelBloomer>Nombre:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={''}
                    type="text"
                    id={'txtNombre'}
                    name={'txtNombre'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal>
                <LabelBloomer>Email:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={'input-contact'}
                    type="email"
                    id={'txtEmail'}
                    name={'txtEmail'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal>
                <LabelBloomer>Teléfono:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <InputTextGuzbarraf
                    className={'input-contact'}
                    type="phone"
                    id={'txtTelefono'}
                    name={'txtTelefono'}
                    placeholder=''
                  />
                </Control>
              </FieldBody>
            </Field>
          </Column>

          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <Field isHorizontal={bolHorizontal}>
              <FieldLabel isNormal>
                <LabelBloomer>Mensaje:</LabelBloomer>
              </FieldLabel>
              <FieldBody>
                <Control>
                  <TextAreaGuzbarraf
                    className={''}
                    id={'txtMensaje'}
                    name={'txtMensaje'}
                    placeholder={''}
                  />
                </Control>
              </FieldBody>
            </Field>

            <Field isHorizontal={bolHorizontal}>
              <FieldLabel /> {/* empty for spacing */}
              <FieldBody>
                <Field>
                  <Control className={'align-button'}>
                    <ButtonGuzbarraf
                      text={'ENVIAR'}
                      bgHover={''}
                      onClick={() => handleSendFormContact()}
                    />
                  </Control>
                </Field>
              </FieldBody>
            </Field>
          </Column>

        </Columns>
      </form>

      <Modal isActive={showMessage}>
        <ModalBackground />
        <ModalContent>
          <ContMessage id="msg-content">&nbsp;</ContMessage>
        </ModalContent>
        <ModalClose onClick={() => handleCloseMessage()} />
      </Modal>

    </ContFormContacto>
  )
}

export default FormContactComp
