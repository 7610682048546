import React from 'react';
import {TextAreaRGB} from "./styles";

const TextAreaGuzbarraf = ({className, id, name, placeholder, value}) => {

  return (
    <TextAreaRGB
      className={className}
      type="text"
      id={id}
      name={name}
      placeholder={placeholder}
    >
      {value}
    </TextAreaRGB>
  )
}

export default TextAreaGuzbarraf
