import React from "react"
import {Column, Columns, Container} from "bloomer";
import {GlobalStyle} from "../../styles/GlobalStyles";
import {GlobalStyleContact} from "./styles";
import FormContactComp from "./formContact";

const ContactoComp = () => {

  return(
    <Container className='full-height'>
      <div className='cont-page page-contacto'>
        <GlobalStyle/>
        <GlobalStyleContact/>
        <Columns className="columns-responsive full-height">

          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <FormContactComp/>
          </Column>

        </Columns>
      </div>
    </Container>
  )
}

export default ContactoComp
