import React from 'react';
import {InputTextRGB} from "./styles";

const InputTextGuzbarraf = ({className, id, name, placeholder, value}) => {

  return (
    <InputTextRGB
      className={className}
      type="text"
      id={id}
      name={name}
      placeholder={placeholder}
    >
      {value}
    </InputTextRGB>
  )
}

export default InputTextGuzbarraf
