import styled, { createGlobalStyle }  from 'styled-components'
import {GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStyleContact = createGlobalStyle`
  .page-contacto{
    color: ${GlobalColors.colorPrimary};
  }
  .input-contact{
    font-family: ${GlobalFonts.fontRegular};
    color: ${GlobalColors.colorPrimary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
  @media(min-width: 1408px){
    .control,
    .input-contact{
      width: 100%;
      font-size: 18px;
    }
  }
`
export const ContInfoContacto = styled.div`
  position: relative;
  margin: 0 auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 75%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 65%;
    margin: 10% auto;
  }
  @media(min-width: 1408px){
    width: 65%;
    margin: 10% auto;
  }
`
export const TituloContacto = styled.div`
  margin-bottom: 25px;
  
  font-family: '${GlobalFonts.fontRegular}';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 36px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 40px;
  }
  @media(min-width: 1408px){
    font-size: 40px;
  }
`
export const FraseContacto = styled.div`
  margin-bottom: 25px;
  
  font-family: '${GlobalFonts.fontRegular}';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px){
    font-size: 20px;
  }
`
export const AtteContacto = styled.div`
  font-family: 'NunitoSansLight';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 22px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 26px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 28px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 28px;
  }
  @media(min-width: 1408px){
    font-size: 32px;
  }
`
export const NombreContacto = styled.div`
  font-family: 'TravelingTypewriter';
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 28px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 30px;
  }
  @media(min-width: 1408px){
    font-size: 34px;
  }
`
export const ContImgContact = styled.div`
  position: relative;
  margin: 0 auto;
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > img{
    width: 100%;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 50%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 50%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 50%;
  }
  @media(min-width: 1408px){
    width: 50%;
  }
`

