import styled from 'styled-components';
import {TextArea} from "bloomer";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const TextAreaRGB = styled(TextArea)`
  
  font: 20px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorSecondary};
  
  border: none;
  border-radius: 0;
  background-color: ${GlobalBackgroundColors.bgSecondary};
  
  &:focus{
    box-shadow: none;
  }
  &:hover{
    
  }
`
